<div class="example-1 card">
    <div class="wrapper" [ngStyle]="{ 
      'background-image': item?.thumbnail 
        ? 'url(' + (item.isShare ? basePhotoUrl + item.thumbnail : photoUrl + item.thumbnail) + ')' 
        : 'url(' + logoUrl + ')'
    }">


    <div class="date">

        <span class="year">{{item?.creationDate | date: 'dd.MM.yyyy'}}</span>
    </div>
    <div class="data">
        <div class="content text-center">
            <p class="title line-clamp-three"><a
                    [routerLink]="item.isShare === true ? ['/haber-detay', item.id,'true'] : ['/haber-detay', item.id,'false']"
                    class="d-block">{{ item?.title }}</a></p>
        </div>
    </div>
</div>
</div>