
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';



@Injectable({
    providedIn: 'root'
})
export class BaseService {
    path = environment.apiUrl;
    basePath = environment.baseApiUrl


    constructor(private http: HttpClient) {}

    baseData(url) {
        const dataList$ = this.http.get(this.path + url);
        const dataBaseList$ = this.http.get(this.basePath + url);
        return forkJoin({
            baseList: dataBaseList$.pipe(
                map((data: any) => data?.data?.filter(item => item.isActive === true && item.isDeleted === false && item.isShare === true))
            ),
            list: dataList$.pipe(
                map((data: any) => data?.data?.filter(item => item.isActive === true && item.isDeleted === false))
            )
        }).pipe(
            map((data: { baseList: any[], list: any[] }) => {
                const baseList = data.baseList;
                const dataList = data.list;

                const mergedDataArray = [...baseList, ...dataList];
                return mergedDataArray.sort((a, b) => {
                    if (a.order != null && b.order != null) {
                      return a.order - b.order; 
                    } else if (a.order == null && b.order == null) {
                      return new Date(a.date).getTime() - new Date(b.date).getTime();
                    } else {
                      return a.order == null ? 1 : -1; 
                    }
                  });
            })
        );
    }
}